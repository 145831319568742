import React from 'react';
import { Box } from '@rsos/components/capstone/base';
import Dropdown from '@rsos/components/capstone/base/Dropdown';
import {
  DropdownBody,
  DropdownList,
} from '@rsos/components/capstone/base/Dropdown/DropdownComponents';
import { HamburgerMenu } from '../../common/common.styles';
import {
  ICSPDropdownDialog,
  LogoutDropdownListItem,
} from './ICSPNavigationControl.styles';

const ICSPNavigationControl = ({ handleLogout }) => {
  return (
    <Box position="relative">
      <Dropdown triggerElement={HamburgerMenu}>
        <ICSPDropdownDialog top={40} right={6} width="113px">
          <DropdownBody>
            <DropdownList borderless>
              <LogoutDropdownListItem>
                <Box onClick={handleLogout}>Log Out</Box>
              </LogoutDropdownListItem>
            </DropdownList>
          </DropdownBody>
        </ICSPDropdownDialog>
      </Dropdown>
    </Box>
  );
};

export default ICSPNavigationControl;
