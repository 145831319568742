import styled from 'styled-components';
import { Absolute } from '@rsos/components/capstone/base';

export const ContainerCircle = styled.div`
  background-color: ${({ theme }) => theme.colors.primaryText};
  border-radius: 50%;
  position: relative;
  width: 32px;
  height: 32px;
  margin-left: 10px;
`;

export const StatusCircle = styled(Absolute)`
  right: -2px;
  top: -2px;
  width: 13px;
  height: 13px;
  background-color: ${({ theme, hasAlert }) =>
    hasAlert ? theme.colors.success : theme.colors.secondaryText};
  border: ${({ theme }) => `1px solid ${theme.colors.background}`};
  border-radius: 50%;
`;

export const InfoBox = styled(Absolute)`
  background-color: ${({ theme }) => theme.colors.primaryText};
  top: 100%;
  border-radius: 5px;
  right: 0px;
  padding: 10px;
  width: max-content;
  max-width: 167px;
  font-size: 12px;
  display: none;

  ${ContainerCircle}:hover & {
    display: block;
  }
`;
