import styled from 'styled-components';
import {
  DropdownDialog,
  DropdownListItem,
} from '@rsos/components/capstone/base/Dropdown/DropdownComponents';

export const ICSPDropdownDialog = styled(DropdownDialog)`
  border: ${({ theme }) => `1px solid ${theme.colors.heavyLineOutline}`};
`;
export const LogoutDropdownListItem = styled(DropdownListItem)`
  justify-content: center;
`;
