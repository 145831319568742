import React, { useState } from 'react';
import { LogoCompactIcon } from '@rsos/assets/icons';
import { gspx } from '@rsos/capstone';
import { Text, Box } from '@rsos/components/capstone/base';
import formatPhone from '@rsos/ellington/src/formatters/helpers/formatPhone';
import {
  useSelectedAlert,
  useCurrentAdrData,
} from '@rsos/reduxDataStore/src/features/selectors/alertsSelectors';
import {
  ContainerCircle,
  StatusCircle,
  InfoBox,
} from './ICSPNavigation.styles';
import ICSPNavigationControl from './ICSPNavigationControl';

const ICSPNavigation = ({ handleLogout }) => {
  const currentAlert = useSelectedAlert();
  const currentAdrData = useCurrentAdrData(currentAlert);
  const siteType = currentAlert?.site_type?.display_name;
  const emergencyType = currentAlert?.emergency_type?.display_name;
  const serviceProviderName =
    currentAlert?.service_provider_name?.display_name ||
    currentAlert?.service_provider_name;
  const alertOwner = currentAdrData?.body?.user?.full_name;
  const alertOwnerPhoneNumber = formatPhone(
    currentAdrData?.body?.user?.phone_number,
  );
  const covering_psap = currentAlert?.covering_psap;
  const [delayHandler, setDelayHandler] = useState(null);

  const InfoText = covering_psap
    ? 'The ECC has ALERTS. Ask ECC to reference RapidSOS'
    : 'ECC does not have Alerts';

  const trackHovered911Icon = () => {
    clearTimeout(delayHandler);

    setDelayHandler(
      setTimeout(() => {
        gspx.trackCustomEvent('iCSP', {
          name: 'Hover 911 Alerts Icon',
          Category: 'iCSP',
          'Launched date': new Date(),
          AlertID: currentAlert?.alert_id,
        });
      }, 500),
    );
  };

  const handleLogoutWithMetric = () => {
    gspx.trackCustomEvent('iCSP', {
      name: 'Click Logout Button',
      Category: 'iCSP',
      'Launched date': new Date(),
      AlertID: currentAlert?.alert_id,
    });

    handleLogout();
  };

  return (
    <Box horizontal align="center" width="100%" justify="space-between">
      <Box horizontal align="center">
        <LogoCompactIcon />
        {siteType && emergencyType && serviceProviderName && (
          <Text color="primaryBase" inline pl="10px">
            {siteType}, {emergencyType}, {serviceProviderName}
          </Text>
        )}
      </Box>

      <Box horizontal align="center">
        {!!Object.keys(currentAlert).length && (
          <>
            <Text color="primaryBaseC2" inline>
              {alertOwner && `${alertOwner},`} {alertOwnerPhoneNumber}
            </Text>
            <ContainerCircle onMouseEnter={trackHovered911Icon}>
              <Text
                fontWeight="bold"
                size="xsmall"
                lineHeight="32px"
                textAlign="center"
              >
                911
              </Text>
              <StatusCircle hasAlert={covering_psap} />
              <InfoBox>{InfoText}</InfoBox>
            </ContainerCircle>
          </>
        )}
        <ICSPNavigationControl handleLogout={handleLogoutWithMetric} />
      </Box>
    </Box>
  );
};

export default ICSPNavigation;
